import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { MenuItem } from "@mui/material";
import CommandBar from "../../../../Basis/Components/CommandBar";
import CommandBarButton from "../../../../Basis/Components/CommandBarButton";
import Fieldset from "../../../../Basis/Components/Fieldset";
import FieldsetGroup from "../../../../Basis/Components/FieldsetGroup";
import InnerView from "../../../../Basis/Components/InnerView";
import LoadingIndicator from "../../../../Basis/Components/LoadingIndicator";
import LokalisierterRichTextInput from "../../../../Basis/Components/LokalisierterRichTextInput";
import SimpleSelect from "../../../../Basis/Components/SimpleSelect";
import ViewMainContent from "../../../../Basis/Components/ViewMainContent";
import { FloppyDiskIcon } from "../../../../Basis/FontAwesome";
import AnyUtil, { nameof } from "../../../../Basis/Utils/AnyUtil";
import useChangeTracker from "../../../../Basis/Utils/useChangeTracker";
import useExecute from "../../../../Basis/Utils/useExecute";
import AendereArtikelEMailEinstellungen, { AendereArtikelEMailEinstellungenInput } from "../../Commands/AendereArtikelEMailEinstellungen";
import Buchungsbestaetigungsversandart, { BuchungsbestaetigungsversandartSammlung } from "../../Commands/Buchungsbestaetigungsversandart";
import { translationKeys, useTranslation } from "../../Internationalisierung/i18nSeminare";
import LadeArtikelEMailEinstellungen from "../../Queries/LadeArtikelEMailEinstellungen";

interface SeminarEMailEinstellungenViewParams {
    artikelId: string;
}

interface ArtikelEMailEinstellungenViewState {
    artikelEMailEinstellungenId: string;
    artikelId: string;
    eMailText?: string;
    eMailTextEN?: string;
    buchungsbestaetigungsversandart: Buchungsbestaetigungsversandart;
    artikelGeaendert: string;
}

export interface ArtikelEMailEinstellungenViewProps extends RouteComponentProps<SeminarEMailEinstellungenViewParams> {}

export default function ArtikelEMailEinstellungenView(props: ArtikelEMailEinstellungenViewProps) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [state, setState] = useState<ArtikelEMailEinstellungenViewState>({
        artikelEMailEinstellungenId: "",
        artikelId: "",
        eMailText: "",
        eMailTextEN: "",
        buchungsbestaetigungsversandart: "KEINE",
        artikelGeaendert: "",
    });

    const [{ hasAnyChanges }, reset] = useChangeTracker(state, aendereEMailEinstellungen);

    new LadeArtikelEMailEinstellungen({ artikelIds: [props.match.params.artikelId] }, (eMailEinstellungen) => {
        const einstellung = eMailEinstellungen[0];
        const state: ArtikelEMailEinstellungenViewState = {
            artikelEMailEinstellungenId: einstellung.artikelEMailEinstellungenId,
            artikelId: einstellung.artikelId,
            eMailText: einstellung.eMailText,
            eMailTextEN: einstellung.eMailTextEN,
            buchungsbestaetigungsversandart: einstellung.buchungsbestaetigungsversandart,
            artikelGeaendert: einstellung.artikelGeaendert,
        }
        setState(state);
        reset(state);
        setLoading(false);
    })

    const aendereArtikelEMailEinstellungenExecute = useExecute(new AendereArtikelEMailEinstellungen([LadeArtikelEMailEinstellungen.queryName]));

    async function aendereEMailEinstellungen() {
        await aendereArtikelEMailEinstellungenExecute({
            artikelId: state.artikelId,
            artikelGeaendert: state.artikelGeaendert,
            eMailText: state.eMailText,
            eMailTextEN: state.eMailTextEN,
            buchungsbestaetigungsversandart: state.buchungsbestaetigungsversandart,
        });
    }

    return (
        <LoadingIndicator loading={loading}>
            <InnerView>
                <CommandBar
                    items={[
                        <CommandBarButton
                            icon={<FloppyDiskIcon />}
                            caption={ t(translationKeys.artikelEMailEinstellungenView.speichern) }
                            onClick={aendereEMailEinstellungen}
                            disabled={!hasAnyChanges()}
                        />,
                    ]}
                />
                <ViewMainContent>
                    <FieldsetGroup>
                        <Fieldset
                            caption={t(translationKeys.artikelEMailEinstellungenView.eMailTextCaption)}
                        >
                            <SimpleSelect
                                caption={t(translationKeys.artikelEMailEinstellungenView.eMailVersenden)}
                                value={state.buchungsbestaetigungsversandart}
                                typBezeichnung={AendereArtikelEMailEinstellungen.commandName}
                                feldBezeichnung={nameof<AendereArtikelEMailEinstellungenInput>("buchungsbestaetigungsversandart")}
                                onChange={(e) => setState(prev => ({
                                    ...prev,
                                    buchungsbestaetigungsversandart: e.target.value as Buchungsbestaetigungsversandart,
                                }))}
                                autoFocus
                            >
                                {BuchungsbestaetigungsversandartSammlung.map(
                                    (buchungsbestaetigung, i) => (
                                        <MenuItem key={i} value={buchungsbestaetigung}>
                                            {t(translationKeys.buchungsbesteatigungsversandart[buchungsbestaetigung])}
                                        </MenuItem>
                                    )
                                )}
                            </SimpleSelect>
                            <LokalisierterRichTextInput
                                captions={{
                                    de: t(translationKeys.artikelEMailEinstellungenView.eMailText.de),
                                    en: t(translationKeys.artikelEMailEinstellungenView.eMailText.en),
                                }}
                                feldBezeichnungen={{
                                    de: AnyUtil.nameof<AendereArtikelEMailEinstellungenInput>("eMailText"),
                                    en: AnyUtil.nameof<AendereArtikelEMailEinstellungenInput>("eMailTextEN"),
                                }}
                                typBezeichnung={AendereArtikelEMailEinstellungen.commandName}
                                values={{
                                    de: state.eMailText ?? "",
                                    en: state.eMailTextEN ?? "",
                                }}
                                onChange={(e) => {
                                    setState(prev => ({
                                        ...prev,
                                        eMailText: e.de,
                                        eMailTextEN: e.en,
                                    }));
                                }}
                            />
                        </Fieldset>
                    </FieldsetGroup>
                </ViewMainContent>
            </InnerView>
        </LoadingIndicator>
    );
}
